<template>
  <div class="container px-4 pt-10 mx-auto">
    <div>
      <h2
        class="pb-6 text-lg font-semibold tracking-wider uppercase  text-myyellow"
      >
        All Movies
      </h2>
      <div
        class="grid grid-cols-1 gap-8  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
      >
        <div class="mt-3" v-for="movie in movieList" :key="movie.ratingKey">
          <MovieCard :movie="movie" :genres="movie.Genre" />
        </div>
      </div>
    </div>
    <div v-if="loading"><loading-bar /></div>
    <jw-pagination
      v-if="movies.length > 0"
      :items="movies"
      @changePage="onChangePage"
      :pageSize="10"
    ></jw-pagination>
  </div>
</template>
<script>
import MovieCard from '../MovieCard.vue'
import { onBeforeMount, ref } from 'vue'
import LoadingBar from '../../LoadingBar.vue'
import getMovies from '../composables/getMovies'
import JwPagination from '../../JwPagination.vue'
export default {
  props: [],
  components: {
    MovieCard,
    LoadingBar,
    JwPagination
  },
  setup () {
    const { movies, loading, loadMovies } = getMovies()
    const movieList = ref([])

    onBeforeMount(() => {
      loadMovies()
    })

    const onChangePage = async pageOfItems => {
      movieList.value = await pageOfItems
    }
    
    return { movies, movieList, loading, onChangePage }
  }
}
</script>
<style></style>
