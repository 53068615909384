import axios from 'axios';
import { useStore } from "vuex"
import { ref, computed } from 'vue';

const getMovies = () => {
  const store = useStore()
  let movies = computed(function () {
    return store.state.movies
  })
  const loading = ref(false)
  const loadMovies = async () => {
    if (movies.value.length > 0) {
      loading.value = false;
    } else {
      loading.value = true;
      axios.get(`https://plex.nzbhorizon.com/library/sections/1/all?X-Plex-Token=${process.env.VUE_APP_X_Plex_Token}`)
        .then(res => {
          res.data.MediaContainer.Metadata.forEach(t => {
            t.X_Plex_Token = process.env.VUE_APP_X_Plex_Token
          })
          store.commit('setMovies', res.data.MediaContainer.Metadata)
          loading.value = false;
        }).catch(err => {
          loading.value = false;
        });      
    }

  }

  return { movies, loading, loadMovies };
}

export default getMovies;
