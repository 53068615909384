<script>
  import formatDate from "../../composables/formatDate";
  import VLazyImage from "v-lazy-image"

  export default {
  components: { VLazyImage },
    props: ["movie", "genres"],
    setup() {
      return { formatDate };
    },
  };
</script>


<template>
  <router-link :to="{ name: 'PlexMovie', params: { id: movie['ratingKey'] } }">
    <v-lazy-image :src="`https://plex.nzbhorizon.com/photo/:/transcode?width=200&height=300&minSize=1&upscale=1&url=${movie.thumb}&X-Plex-Token=${movie.X_Plex_Token}`" src-placeholder="https://via.placeholder.com/200x300" class="transition ease-in-out duration-400 hover:opacity-80" alt="poster"/>
  </router-link>
  <div class="mt-2">
    <router-link
      :to="{ name: 'MoviesShow', params: { id: movie['ratingKey'] } }"
      class="mt-2 text-lg dark:hover:text-gray-300 hover:text-gray-500"
    >
      {{ movie["title"] }}</router-link
    >
    <div class="flex items-center mt-1 text-sm text-gray-500 dark:text-gray-400">
      <svg class="w-4 text-gray-700 fill-current dark:text-myyellow" viewBox="0 0 24 24">
        <g data-name="Layer 2">
          <path
            d="M17.56 21a1 1 0 01-.46-.11L12 18.22l-5.1 2.67a1 1 0 01-1.45-1.06l1-5.63-4.12-4a1 1 0 01-.25-1 1 1 0 01.81-.68l5.7-.83 2.51-5.13a1 1 0 011.8 0l2.54 5.12 5.7.83a1 1 0 01.81.68 1 1 0 01-.25 1l-4.12 4 1 5.63a1 1 0 01-.4 1 1 1 0 01-.62.18z"
            data-name="star"
          />
        </g>
      </svg>
      <span class="ml-1" v-if="movie['audienceRating']">{{ Math.round(movie["audienceRating"] * 10) }}%</span>
      <span class="ml-1" v-else-if="movie['rating']">{{ Math.round(movie["rating"] * 10) }}%</span>
      <span class="ml-1" v-else>N/A</span>
      <span class="mx-2">|</span>
      <span>{{ formatDate(movie["originallyAvailableAt"]) }}</span>
    </div>
    <div class="text-sm text-gray-500 truncate dark:text-gray-400" v-if="genres">{{ genres.map(a => a.tag).join(', ') }}</div>
  </div>
</template>
