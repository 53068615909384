<template>
  <all-movies />
</template>

<script>
  import AllMovies from "./components/AllMovies.vue";

  export default {
    components: { AllMovies },
  };
</script>

<style>
</style>
